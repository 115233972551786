<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="11" offset-lg="1" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                          <ValidationProvider name="Org Id" vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="researchThematicArea.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Thematic Area Name (En)" vid="thematic_area_name" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="thematic_area_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{ $t('research_manage.thematic_area_name_en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="thematic_area_name"
                                v-model="researchThematicArea.thematic_area_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Thematic Area Name (Bn)" vid="thematic_area_name_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="thematic_area_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{ $t('research_manage.thematic_area_name_bn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="thematic_area_name_bn"
                                v-model="researchThematicArea.thematic_area_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Thematic Area Description (En)" vid="thematic_area_description">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="thematic_area_description"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{ $t('research_manage.thematic_area_description_en') }}
                              </template>
                              <b-form-textarea
                                id="thematic_area_description"
                                v-model="researchThematicArea.thematic_area_description"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Thematic Area Description (Bn)" vid="thematic_area_description_bn">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="thematic_area_description"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{ $t('research_manage.thematic_area_description_bn') }}
                              </template>
                              <b-form-textarea
                                id="thematic_area_description_bn"
                                v-model="researchThematicArea.thematic_area_description_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                          </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { researchThematicAreaStore, researchThematicAreaUpdate } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.researchThematicArea.org_id = this.$store.state.Auth.authUser.org_id
      // if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
      //   this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
      // }
    }
    if (this.id) {
      const tmp = this.getEditingData()
      this.researchThematicArea = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      researchThematicArea: {
        org_id: 0,
        thematic_area_name: '',
        thematic_area_name_bn: '',
        thematic_area_description: '',
        thematic_area_description_bn: ''
      }
    }
  },
  computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      }
  },
  methods: {
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.researchThematicArea.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${researchThematicAreaUpdate}/${this.id}`, this.researchThematicArea)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, researchThematicAreaStore, this.researchThematicArea)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
